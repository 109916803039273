import { createSlice } from "@reduxjs/toolkit";

const initialState: { [k: string]: LightsData } = {
  EMRG: { selected: "red", colors: ["red", "green", "off"] },
};
export const lightsDataSlice = createSlice({
  name: "lightsData",
  initialState: initialState,
  reducers: {
    update: (state, data) => {
      state = data.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = lightsDataSlice.actions;

export default lightsDataSlice.reducer;

export interface LightsData {
  selected: string;
  colors: string[];
}
