import { createSlice } from "@reduxjs/toolkit";
import { getDefaultRole, LdapRoles } from "../lib/auth/ldapRoles";

const defaultPermissions: LdapRoles = getDefaultRole();

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState: defaultPermissions,
  reducers: {
    set: (state, data) => {
      state = data.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set } = permissionsSlice.actions;

export default permissionsSlice.reducer;
