import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import Circle from "@mui/icons-material/Circle";
import Error from "@mui/icons-material/Error";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import React from "react";
import { ServiceDisplayData } from "../../statusSlice";
import { toggleControls as toggleReplayControls } from "../../redux/slices/replay";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

export default function ServicesStatus(props: {
  statuses: ServiceDisplayData[];
  dockerNodes: { up: string[]; down: string[] };
}) {
  const { statuses, dockerNodes } = props;

  const iconOK = <Circle style={{ color: "#0ec416" }} />;
  const iconFail = <Error color={"error"} />;
  const iconWarn = <ReportProblemIcon style={{ color: "#ff9100" }} />;

  return (
    <>
      <NodeStatuses dockerNodes={dockerNodes} />
      <List dense>
        <ListSubheader>Docker Services</ListSubheader>
        {statuses?.map((task) => {
          const icon = () => {
            switch (task.status) {
              case Status.Okay:
                return iconOK;
              case Status.Warn:
                return iconWarn;
              default:
                return iconFail;
            }
          };
          return (
            <React.Fragment key={task.name}>
              <ListItem
                secondaryAction={
                  <Typography>
                    {task.running}/{task.desired}
                  </Typography>
                }
              >
                <ListItemIcon>{icon()}</ListItemIcon>
                <ListItemText
                  primary={`${task.name.replace("vmonitor_", "")}`}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    </>
  );
}

export function NodeStatuses(props: {
  dockerNodes: { up: string[]; down: string[] };
}) {
  const { dockerNodes } = props;
  const iconOK = <Circle style={{ color: "#0ec416" }} />;
  const iconFail = <Error color={"error"} />;
  const permissions = useAppSelector((state) => state.permissions);
  const dispatch = useAppDispatch();

  return (
    <List dense>
      <ListSubheader>Docker Nodes</ListSubheader>
      {dockerNodes.up.map((node) => (
        <>
          <ListItem>
            <ListItemIcon>{iconOK}</ListItemIcon>
            <ListItemText primary={node} />
          </ListItem>
          <Divider />
        </>
      ))}
      {dockerNodes.down.map((node) => (
        <>
          <ListItem>
            <ListItemIcon>{iconFail}</ListItemIcon>
            <ListItemText primary={node} />
          </ListItem>
          <Divider />
        </>
      ))}
    </List>
  );
}

export interface ServiceStatus {
  result: string;
  table: number;
  _start: Date;
  _stop: Date;
  _time: Date;
  _value: number;
  _field: string;
  _measurement: string;
  host: string;
  service_id: string;
  service_mode: string;
  service_name: string;
  status?: Status;
}

export enum Status {
  Okay,
  Warn,
  Fail,
}
