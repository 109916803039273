import { createSlice } from "@reduxjs/toolkit";

const initialState: { [k: string]: Barrier } = {
  "1A": {
    type: "Inbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: true,
    physicalPLC: "1A",
    forcible: false,
    trackPrefix: "GR",
    iconToMidpointBearing: 288.34005017429126,
    passthrough: ["SLS"],
    icon: [12.7377085943, 57.7774360292],
    midpoint: [12.7375917966, 57.7774566738],
  },
  "1B": {
    type: "Outbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: false,
    physicalPLC: "1B",
    forcible: false,
    trackPrefix: "GR",
    iconToMidpointBearing: 109.77011087272389,
    passthrough: [],
    icon: [12.7373893361, 57.7774839903],
    midpoint: [12.7375066797, 57.777461501],
  },
  "2A": {
    type: "Inbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: false,
    physicalPLC: "2A",
    forcible: false,
    trackPrefix: "HPG",
    iconToMidpointBearing: 16.470337341849522,
    passthrough: [
      "HT1",
      "HT2",
      "SKID",
      "HST",
      "CS",
      "B/H",
      "CT",
      "DBT",
      "COT",
      "CTC",
      "RR",
    ],
    icon: [12.739589524, 57.7752058222],
    midpoint: [12.7396235995, 57.7752672815],
  },
  "2B": {
    type: "Outbound",
    mode: "Automatic",
    forcible: true,
    directionDisable: "None",
    disableOnTrackClose: false,
    physicalPLC: "2B",
    icon: [12.7396747217, 57.7753589351],
    midpoint: [12.7396403953, 57.7752970317],
    iconToMidpointBearing: 196.47244785259784,
    trackPrefix: "HPG",
    passthrough: [],
  },
  "3A": {
    type: "Inbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: false,
    physicalPLC: "3A",
    forcible: false,
    trackPrefix: "HST",
    iconToMidpointBearing: 0,
    passthrough: [],
    icon: [12.7419208192, 57.7756955793],
    midpoint: [12.74179, 57.7757110381],
  },
  "3B": {
    type: "Outbound",
    mode: "Automatic",
    forcible: true,
    directionDisable: "None",
    disableOnTrackClose: false,
    physicalPLC: "3B",
    icon: [12.7413039777, 57.7753047339],
    midpoint: [12.7414285659, 57.7752829582],
    iconToMidpointBearing: 108.14763228630001,
    trackPrefix: "HST",
    passthrough: [],
  },
  "4A": {
    type: "Inbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: true,
    physicalPLC: "4A",
    forcible: true,
    trackPrefix: "HT2",
    iconToMidpointBearing: 19.2120830871399,
    passthrough: [],
    icon: [12.7449071375, 57.7742658013],
    midpoint: [12.7449534362, 57.7743366504],
  },
  "4B": {
    type: "Outbound",
    mode: "Automatic",
    forcible: true,
    directionDisable: "None",
    disableOnTrackClose: false,
    physicalPLC: "4B",
    icon: [12.7450190368, 57.7744375344],
    midpoint: [12.7449733422, 57.7743673469],
    iconToMidpointBearing: 199.14537449059654,
    trackPrefix: "HT2",
    passthrough: [],
  },
  "5A": {
    type: "Inbound",
    mode: "Automatic",
    forcible: true,
    directionDisable: "None",
    disableOnTrackClose: false,
    physicalPLC: "5A",
    icon: [12.7434891445, 57.7749524372],
    midpoint: [12.7433709009, 57.7749718074],
    iconToMidpointBearing: 287.07729068734096,
    trackPrefix: "DBT",
    passthrough: [],
  },
  "5B": {
    type: "Outbound",
    mode: "Automatic",
    forcible: true,
    directionDisable: "None",
    disableOnTrackClose: false,
    physicalPLC: "5B",
    icon: [12.7431962116, 57.7750068175],
    midpoint: [12.743313674, 57.7749879658],
    iconToMidpointBearing: 106.75021457822996,
    trackPrefix: "DBT",
    passthrough: [],
  },
  "10A": {
    type: "Inbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: true,
    physicalPLC: "10A",
    forcible: false,
    trackPrefix: "CRT",
    iconToMidpointBearing: 304.45640049546205,
    passthrough: ["SLS", "AZ"],
    icon: [12.7384018577, 57.7771493501],
    midpoint: [12.7383076414, 57.777183821],
  },
  "10B": {
    type: "Outbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: false,
    physicalPLC: "10B",
    forcible: false,
    trackPrefix: "CRT",
    iconToMidpointBearing: 125.26831707071834,
    passthrough: [],
    icon: [12.7381614985, 57.7772349677],
    midpoint: [12.7382527074, 57.7772005734],
  },
  "10C": {
    type: "Inbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: true,
    physicalPLC: "10C",
    forcible: false,
    trackPrefix: "CRT",
    iconToMidpointBearing: 202.94731592143668,
    passthrough: ["SLS", "AZ"],
    icon: [12.7367087002, 57.7769387002],
    midpoint: [12.7366481431, 57.7768624345],
  },
  "10D": {
    type: "Outbound",
    mode: "Automatic",
    forcible: true,
    directionDisable: "None",
    disableOnTrackClose: false,
    physicalPLC: "10D",
    icon: [12.7365797834, 57.7767810604],
    midpoint: [12.7366002822, 57.7768059657],
    iconToMidpointBearing: 23.695528910977902,
    trackPrefix: "CRT",
    passthrough: [],
  },
  "11A": {
    type: "Inbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: true,
    physicalPLC: "11A",
    forcible: false,
    trackPrefix: "CRT",
    iconToMidpointBearing: 183.79475508252767,
    passthrough: ["SLS"],
    icon: [12.7415123929, 57.7837938387],
    midpoint: [12.7415041655, 57.7837277103],
  },
  "11B": {
    type: "Inbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: true,
    physicalPLC: "11B",
    forcible: false,
    trackPrefix: "SLS",
    iconToMidpointBearing: 4.175886959581924,
    passthrough: [],
    icon: [12.741482125, 57.7836354398],
    midpoint: [12.741490792, 57.783698724],
  },
  "22A": {
    type: "Inbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: true,
    physicalPLC: "22A",
    forcible: false,
    trackPrefix: "CRT",
    iconToMidpointBearing: 133.36440893623256,
    passthrough: ["AZ"],
    icon: [12.7544298948, 57.7860851188],
    midpoint: [12.7544753028, 57.7860622566],
  },
  "22A_Out": {
    type: "Outbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: false,
    physicalPLC: "22A",
    forcible: false,
    trackPrefix: "CRT",
    iconToMidpointBearing: 133.36440893623256,
    passthrough: [],
    icon: [12.7544298948, 57.7860851188],
    midpoint: [12.7544753028, 57.7860622566],
  },
  "22B": {
    type: "Inbound",
    directionDisable: "None",
    mode: "Automatic",
    disableOnTrackClose: true,
    physicalPLC: "22B",
    forcible: false,
    trackPrefix: "AZ",
    iconToMidpointBearing: 2.7861239338340567,
    passthrough: [],
    icon: [12.7575234918, 57.7861802968],
    midpoint: [12.7575290624, 57.786241317],
  },
  "12D": {
    type: "Inbound",
    mode: "Manual",
    forcible: true,
    directionDisable: "None",
    disableOnTrackClose: false,
    physicalPLC: "12D",
    icon: [12.7390650547, 57.7752418616],
    midpoint: [12.739187105, 57.775216296],
    iconToMidpointBearing: 111.44580273633524,
    trackPrefix: "B/H",
    passthrough: [],
  },
  "12B": {
    type: "Outbound",
    mode: "Automatic",
    forcible: true,
    directionDisable: "None",
    disableOnTrackClose: false,
    physicalPLC: "12B",
    icon: [12.7393837266, 57.7751752],
    midpoint: [12.7392660003, 57.7751993243],
    iconToMidpointBearing: 291.02119636405365,
    trackPrefix: "B/H",
    passthrough: [],
  },
  "12C": {
    type: "Inbound",
    mode: "Automatic",
    forcible: true,
    directionDisable: "None",
    disableOnTrackClose: false,
    physicalPLC: "12C",
    icon: [12.7341919066, 57.7651394149],
    midpoint: [12.7341919066, 57.7651394149],
    iconToMidpointBearing: 0,
    trackPrefix: "B/H",
    passthrough: [],
  },
};

export const barriersSlice = createSlice({
  name: "barriers",
  initialState: initialState,
  reducers: {
    update: (state, data) => {
      state = data.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = barriersSlice.actions;

export default barriersSlice.reducer;

export interface Barrier {
  type: "Inbound" | "Outbound";
  directionDisable: "None" | "Clockwise" | "CounterClockWise";
  mode: "Automatic" | "Manual";
  disableOnTrackClose: boolean;
  physicalPLC: string;
  forcible: boolean;
  trackPrefix: string;
  iconToMidpointBearing: number;
  passthrough: string[];
  icon: number[];
  midpoint: number[];
}
