import { createSlice } from "@reduxjs/toolkit";

const initialState = { status: "Inactive" };

export const plcStatusSlice = createSlice({
  name: "plc-status",
  initialState: initialState,
  reducers: {
    update: (state, data) => {
      state = data.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = plcStatusSlice.actions;

export default plcStatusSlice.reducer;

export interface PlcStatus {
  status: string;
}
