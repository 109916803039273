import { createTheme } from "@mui/material/styles";
import { PaletteColorOptions, PaletteOptions } from "@mui/material";

export const theme = createTheme({
  palette: {
    white: {
      main: "#FFFFFF",
    },
  },
  // components: {
  //     MuiButton: {
  //         defaultProps: {
  //             size: "small",
  //             sx: {
  //                 minWidth: 0
  //             }
  //         }
  //     }
  // }
});

declare module "@mui/material/styles" {
  interface Theme {
    palette: {
      white: PaletteColorOptions;
    };
    status: {
      danger: string;
    };
  }
  interface PaletteOptions {
    white: PaletteColorOptions;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
    palette?: PaletteOptions;
  }
}

export default theme;
