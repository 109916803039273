import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertColor } from "@mui/material";

interface ToastProps {
  severity?: AlertColor;
  message: string;
  show?: boolean;
}
const initialState: ToastProps = {
  severity: "error",
  message: "",
  show: false,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState: initialState,
  reducers: {
    set: (state, data: PayloadAction<ToastProps>) => {
      state = { ...state, ...data.payload, show: data.payload?.show ?? true };
      return state;
    },
    clear: (state) => {
      state = { ...state, message: "", show: false };
      return state;
    },
    hide: (state) => {
      state = { ...state, show: false };
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set, clear, hide } = alertSlice.actions;

export default alertSlice.reducer;
