import { createSlice } from "@reduxjs/toolkit";

export const trackerWindowsSlice = createSlice({
  name: "trackerWindows",
  initialState: [],
  reducers: {
    remove: (state, data) => {
      /** @param {Array} state */
      // const index = state.indexOf(data.payload)
      state = state.filter((item) => item != data.payload);
      console.log(state, data);
      return state;
    },
    set: (state, data) => {
      state = data.payload;
      return state;
    },
    add: (state, data) => {
      state.push(data.payload);
      return state;
    },
    toggle: (state, data) => {
      const id = data.payload;
      const index = state.indexOf(id);
      if (index >= 0) {
        state.splice(index, 1);
      } else {
        state.push(id);
      }
      return state;
    },
    clear: (state) => {
      state = [];
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { remove, set, add, toggle, clear } = trackerWindowsSlice.actions;

export default trackerWindowsSlice.reducer;
