import { createSlice } from "@reduxjs/toolkit";

export const hoveredTrackerSlice = createSlice({
  name: "hoveredTracker",
  initialState: "",
  reducers: {
    set: (state, data) => {
      state = data.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set } = hoveredTrackerSlice.actions;

export default hoveredTrackerSlice.reducer;
