import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const trackerSlice = createSlice({
  name: "trackers",
  initialState: {},
  reducers: {
    update: (state, data) => {
      // console.log(data.payload);
      const timeOfLastPacket = state[data.payload.IMEI]?.time ?? Date.now();
      console.log(
        `Time since ${data.payload.IMEI} last packet (ms): ${Date.now() - timeOfLastPacket}`
      );
      state[data.payload.IMEI] = data.payload;
      // console.log(state)
      return state;
    },
    remove: (state, data) => {
      console.log(data.payload);
      delete state[data.payload];
    },
    clear: (state) => {
      state = {};
      return state;
    },
    clearArray: (state, data: PayloadAction<number[] | string[]>) => {
      const keepIDs = data.payload?.map((id) => `${id}`);
      const filteredEntries = Object.entries(state).filter(([id, info]) =>
        keepIDs.includes(id)
      );
      state = Object.fromEntries(filteredEntries);
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update, remove, clear, clearArray } = trackerSlice.actions;

export default trackerSlice.reducer;
