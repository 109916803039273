import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServiceStatus, Status } from "./components/Header/ServicesStatus";

const initialState: StatusSlice = {
  websocket: false,
  webui: false,
  services: [],
  nodes: { up: [], down: [] },
};

export const statusSlice = createSlice({
  name: "status",
  initialState: initialState,
  reducers: {
    set: (state, data) => {
      state = { ...state, ...data.payload };
      return state;
    },
    updateServices: (state, data: PayloadAction<ServiceStatus[]>) => {
      // console.log(data.payload)
      const tasksDesired = data.payload?.filter(
        (status) => status._field === "tasks_desired"
      );
      const tasksRunning = data.payload?.filter(
        (status) => status._field === "tasks_running"
      );
      const tasks = tasksDesired.map((task) => {
        const runningTask = tasksRunning.find(
          (running) => task.service_id === running.service_id
        );
        let returnData = {
          name: runningTask?.service_name ?? task.service_name,
          running: runningTask?._value ?? 0,
          desired: task._value,
          status: Status.Fail,
        };

        if (task.service_mode === "global") {
          returnData.desired = state.nodes.up.length + state.nodes.down.length;
          if (returnData.running > state.nodes.up.length)
            returnData.running = state.nodes.up.length;
        }

        if (task._value === runningTask?._value) {
          returnData.status = Status.Okay;
        } else {
          returnData.status = Status.Warn;
          if (runningTask?._value === 0) {
            returnData.status = Status.Fail;
          }
        }
        return returnData;
      });

      return {
        ...state,
        services: tasks,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { set, updateServices } = statusSlice.actions;

export default statusSlice.reducer;

export interface StatusSlice {
  websocket: boolean;
  webui: boolean;
  services: ServiceDisplayData[];
  nodes: { up: string[]; down: string[] };
}

export interface ServiceDisplayData {
  name: string;
  running: number;
  desired: number;
  status: Status;
}
