import { createSlice } from "@reduxjs/toolkit";

const initialState: Categories = {
  Volvo: {
    name: "Volvo",
    icon: "BlueDiamond.png",
    internal: false,
    default: true,
  },
  Maintenance: {
    name: "Maintenance",
    icon: "RedDiamondWhiteCenter.png",
    internal: true,
    default: true,
  },
  Emergency: {
    name: "Emergency",
    icon: "BlueDiamondWhiteCenter.png",
    internal: true,
    default: true,
  },
  RTS: { name: "RTS", icon: "Green Diamond", internal: false, default: false },
  Default: {
    name: "Default",
    icon: "GreenDiamond.png",
    internal: false,
    default: true,
  },
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {
    update: (state, data) => {
      state = data.payload.categories;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = categoriesSlice.actions;

export default categoriesSlice.reducer;

export interface Categories {
  [k: string]: Category;
}

export interface Category {
  name: string;
  icon: string;
  internal: boolean;
  default: boolean;
}
