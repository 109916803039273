import { createSlice } from "@reduxjs/toolkit";

export const pageModalOpenSlice = createSlice({
  name: "pageModalOpen",
  initialState: false,
  reducers: {
    set: (state, data) => {
      state = data.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set } = pageModalOpenSlice.actions;

export default pageModalOpenSlice.reducer;
