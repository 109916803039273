import "../styles/global.scss";
import "../public/fonts/style.css";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import { Provider } from "react-redux";
import store from "../src/reduxStore";
import { ThemeProvider } from "@mui/material";
import { theme } from "../src/theme";
import { LicenseInfo } from "@mui/x-license-pro";
import { SWRConfig } from "swr";
import createEmotionCache from "../src/createEmotionCache";
import { CacheProvider, EmotionCache } from "@emotion/react";
import Head from "next/head";

LicenseInfo.setLicenseKey(
  "32611007ff41744f4ec0937a5400bdd6Tz00OTMwOSxFPTE2OTIzODQ5NzQzODIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

// function SafeHydrate ({ children }) {
//     return (
//         <div suppressHydrationWarning>
//             {typeof window === 'undefined' ? null : children}
//         </div>
//     )
// }

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
export { clientSideEmotionCache };

// @ts-ignore
const fetcher = (...args) => fetch(...args).then((res) => res.json());

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  // @ts-ignore
  pageProps: { session, ...pageProps },
}: MyAppProps) {
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <SessionProvider session={session}>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
          </Head>
          <Provider store={store}>
            <SWRConfig value={{ fetcher: fetcher }}>
              <Component {...pageProps} />
            </SWRConfig>
          </Provider>
        </SessionProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
