// Taken from the database schema, but has to be manually updated
export interface LdapRoles {
  id: number;
  textid: string;
  name: string;
  created_at?: Date | string;
  modified_at?: Date | string;
  canModifyConfig: boolean;
  canModifyAlarms: boolean;
  canAccessAlarms: boolean;
  canAccessBookings: boolean;
  canAccessReports: boolean;
  canAccessPLC: boolean;
  canModifyUsers: boolean;
  canModifyTrackers: boolean;
}

// We want to be able to always return the proper data format, even for unauthorized and non-existent users
export function getDefaultRole(override?): LdapRoles {
  return {
    canModifyAlarms: override ?? false,
    canAccessAlarms: override ?? false,
    canAccessBookings: override ?? false,
    canModifyConfig: override ?? false,
    canAccessPLC: override ?? false,
    canAccessReports: override ?? false,
    canModifyUsers: override ?? false,
    canModifyTrackers: override ?? false,
    id: 0,
    name: "",
    textid: "",
    modified_at: new Date(),
    created_at: new Date(),
  };
}
